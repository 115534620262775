import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { styles } from './styles'
import { getFormattedDateTime } from '../../../service/codeUtils'
import Input from '../../../elements/Input'
import { auditorKycSubmit } from '../../../redux/thunks/kyc.thunk'
import CustomButton from '../../../elements/CustomButton'
import { APICallStatus, KycAction } from '../../../constants'
import layout from '../../../constants/layout'
import { showErrorToast } from '../../../service/toast'

export default function AuditorUserDetailsModal({
  visible,
  userDetails,
  onClose,
  isActionableModal,
}) {

  const [agentRemarks, setAgentRemarks] = useState();
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  const onSubmit = async (action) => {
    if(action === KycAction.APPROVE)
      setIsLoadingApprove(true);
    else
      setIsLoadingReject(true);
    const response = await auditorKycSubmit({
      bookingId: userDetails?.bookingId,
      action,
      comments: agentRemarks
    });
    setIsLoadingApprove(false);
    setIsLoadingReject(false);
    if(response?.status === APICallStatus.SUCCESS) {
      onClose();
    } else {
      showErrorToast(response?.message);
    }
  }

  return (
    <Modal show={visible} onHide={onClose} size="lg" >
      <Modal.Header closeButton>
        <Modal.Title>User details</Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column align-items-center'>
        {userDetails?.userProfilePhoto ? <a href={userDetails?.userProfilePhoto} target='_blank'>
          <img src={userDetails?.userProfilePhoto} style={styles.userProfile}/>
        </a> : null}
        <p className="mb-0" style={styles.userName}>{userDetails?.userName}</p>
        <p className="mb-0" style={styles.country}>{userDetails?.userCountryName}</p>

        <div style={styles.horizontalLine}/>

        <div className="d-flex justify-content-between" style={{width: '100%'}}>
          <div style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Contact Number</p>
            <a className="m-0" style={styles.fieldValue}>{userDetails?.userPhoneNumber}</a>
          </div>
          <div className="d-flex flex-column align-items-end" style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Alternate Number</p>
            {userDetails?.alternatePhoneNumber ? <a className="m-0" style={styles.fieldValue}>{userDetails?.alternatePhoneNumber}</a> : <p className="m-0" style={styles.fieldValue}>--</p>}
          </div>
        </div>

        <div className='d-flex flex-column' style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Current Address</p>
          <a className="m-0" style={styles.fieldValue}>{userDetails?.addressLine1}, {userDetails?.addressLine2}, {userDetails?.city}, {userDetails?.state} {userDetails?.pincode}</a>
        </div>

        <div style={styles.horizontalLine}/>

        <p className='mb-0' style={styles.sectionTitle}>Passport Details</p>

        {/* <div className='d-flex flex-column' style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Passport number</p>
          <p className="m-0" style={styles.fieldValue}>{userDetails?.passportNumber}</p>
        </div> */}

        <div className='d-flex flex-column' style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Passport front image</p>
          <a href={userDetails?.passportFrontImage} target='_blank'>
            <img src={userDetails?.passportFrontImage} style={styles.passportImage}/>
          </a>
        </div>

        {userDetails?.passportBackImage ? <div className='d-flex flex-column' style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Passport back image</p>
          <a href={userDetails?.passportBackImage} target='_blank'>
            <img src={userDetails?.passportBackImage} style={styles.passportImage}/>
          </a>
        </div> : null}

        <div style={styles.horizontalLine}/>

        <p className='mb-0' style={styles.sectionTitle}>Visa Details</p>

        <div className="d-flex justify-content-between" style={{width: '100%'}}>
          <div style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Visa start date</p>
            <p className="m-0" style={styles.fieldValue}>{getFormattedDateTime(userDetails?.visaStartDate).split('|')[0]}</p>
          </div>
          <div className="d-flex flex-column align-items-end" style={styles.fieldContainer}>
            <p className="m-0" style={styles.fieldTitle}>Visa expiry date</p>
            <p className="m-0" style={styles.fieldValue}>{getFormattedDateTime(userDetails?.visaEndDate).split('|')[0]}</p>
          </div>
        </div>

        <div className='d-flex flex-column' style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Visa image</p>
          <a href={userDetails?.visaImage} target='_blank'>
            <img src={userDetails?.visaImage} style={styles.passportImage}/>
          </a>
        </div>

        <div style={styles.horizontalLine}/>

        <div style={styles.fieldContainer}>
          <p className="m-0" style={styles.fieldTitle}>Agent Comments</p>
          <p className="m-0" style={styles.fieldValue}>{userDetails?.agentComments ? userDetails?.agentComments : '--'}</p>
        </div>
        {isActionableModal ? <div style={styles.fieldContainer}>
          <Input label={'Your remarks'} value={agentRemarks} onChange={setAgentRemarks}/>
        </div> : null}
      </Modal.Body>
      <Modal.Footer>
        {isActionableModal ? <div className='d-flex flex-row'>
          <CustomButton title={"Reject"} onClick={() => onSubmit(KycAction.REJECT)} isLoading={isLoadingReject} buttonStyles={{flex: 1, backgroundColor: 'red', borderColor: 'red', marginRight: layout.window.toNormW(22)}} style={{marginRight: layout.window.toNormW(22)}}/>
          <CustomButton title={"Approve"} onClick={() => onSubmit(KycAction.APPROVE)} isLoading={isLoadingApprove} buttonStyles={{flex: 1}}/>
        </div> : null}
        {/* <Button variant="danger" onClick={onClose}>
          Reject
        </Button> */}
        {/* <Button variant="primary" onClick={onClose}>
          Approve
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}
