import { APICallStatus } from "../../constants";
import { Config } from "../../constants/config";
import { ApiService } from "../../service/ApiService"

export const fetchKycUsersList = async () => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.kycUserList);
    return {
      status: APICallStatus.SUCCESS,
      usersList: response?.data?.data,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      usersList: [],
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const verifyKycStartOtp = async ({otp, bookingId}) => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.verifyKycOtp, {
      otp: otp,
      bookingId: bookingId,
    });

    return {
      status: APICallStatus.SUCCESS,
    }
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    }
  }
}

export const agentKycSubmit = async ({bookingId, passportNumber, visaStartDate, visaEndDate, passportFrontImage, passportBackImage, visaImage, action, comments}) => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.agentKycSubmit, {
      bookingId,
      passportNumber,
      visaStartDate,
      visaEndDate,
      passportFrontImage,
      passportBackImage,
      visaImage,
      action,
      comments,
      latitude:"18.123",
      longitude:"-77.322"
    });
    console.log(response);
    return {
      status: APICallStatus.SUCCESS
    }
  } catch (error) {
    console.log(error);
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    }
  }
}

export const auditorKycSubmit = async ({bookingId, action, comments}) => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.auditorKycSubmit, {
      bookingId,
      action,
      comments,
    });
    
    return {
      status: APICallStatus.SUCCESS
    }
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    }
  }
}

export const fetchAuditorKycUserList = async () => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.auditorKycUserList);
    return {
      status: APICallStatus.SUCCESS,
      usersList: response?.data?.data,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      usersList: [],
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const fetchUserKycData = async ({query}) => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.fetchUserKyc, {
      query: query,
    });
    return {
      status: APICallStatus.SUCCESS,
      userData: response?.data?.data,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const fetchAdminUnmappedKycUsers = async () => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.adminUnmappedKycUserList);
    return {
      status: APICallStatus.SUCCESS,
      usersList: response?.data?.data,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      usersList: [],
      message: error?.response?.data?.error?.shortMessage || 'Something went wrong',
    };
  }
}

export const fetchAllAgents = async () => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.fetchAllAgents);
    return {
      status: APICallStatus.SUCCESS,
      agents: response?.data?.data,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage,
    }
  }
}

export const mapBookingToAgent = async ({bookingId, agentUuid}) => {
  try {
    const response = await ApiService.postApi(Config.apis.kyc.mapBookingToAgent, {
      bookingId,
      agentUuid,
    });
    return {
      status: APICallStatus.SUCCESS,
      agents: response?.data?.data,
    };
  } catch (error) {
    return {
      status: APICallStatus.ERROR,
      message: error?.response?.data?.error?.shortMessage,
    }
  }
}
