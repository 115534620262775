import React, { useState, useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";
import "./styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";



const isLocal = false

const PreKycDashboard = () => {
  const [data, setData] = useState([
    // {
    //   name: "John Doe",
    //   phone: "+1 123 456 7890",
    //   category: "General",
    //   nationality: "American",
    // },
  ]);

  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const rowsPerPage = 20;

  // Fetch data on component mount
  useEffect(() => {
    fetchPaginatedData();
  }, [currentPage]);

  const fetchPaginatedData = async () => {
    setSearchQuery('')
    try {
      let data = JSON.stringify({
        "metaData": {},
        "data": {
          "pageNumber": currentPage
        }
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: isLocal ? 'http://localhost:8080/v1/kyc/getPreKycDashboardLeadsPaginated': 'https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/getPreKycDashboardLeadsPaginated',
        headers: {
          'source': 'web',
          'Content-Type': 'application/json',
          deviceid: Math.random()
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        console.log('xvxvxvxvx', JSON.stringify(response.data));
        setData(response.data?.data);
        if (!isSearching) setFilteredData(response.data?.data);
      } catch (error) {
        console.error(error);
        throw error
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setFilteredData(data); // Reset to default data if search is empty
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    try {
      let data = JSON.stringify({
        "metaData": {},
        "data": {
          "pageNumber": currentPage,
          "searchQuery": searchQuery.trim()
        }
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: isLocal ? 'http://localhost:8080/v1/kyc/getPreKycDashboardLeadsPaginated':'https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/getPreKycDashboardLeadsPaginated',
        headers: {
          'source': 'web',
          'Content-Type': 'application/json',
          deviceid: Math.random(),
        },
        data: data
      };

      try {
        const response = await axios.request(config);
        console.log('xvxvxvxvx', JSON.stringify(response.data));
        setData(response.data?.data);
        setIsSearching(false);
        if (!isSearching) setFilteredData(response.data?.data);
      } catch (error) {
        console.error(error);
        throw error
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (row) => {
    console.log(row, "roroor")
    if (!row.category) {
      toast.error("Please choose category!");
      return;
    }
    if (!row.activationCode && row.category == "Category 0") {
      toast.error("Please generate activation code for category 0!");
      return;
    }
    if (!row.action) {
      toast.error("Please choose action!");
      return;
    }
    const data = JSON.stringify({
      metaData: {},
      data: {
        bookingId: row.bookingId,
        category: row.category,
        action: row.action,
        comments: row.comments
      },
    });

    console.log(data, "djjjs");


    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: isLocal ? "http://localhost:8080/v1/kyc/submitPreKycDashboardLeadAction" : "https://prod-lb-n-1003.monyyapp.com/api/v1/kyc/submitPreKycDashboardLeadAction",
      headers: {
        source: "web",
        "Content-Type": "application/json",
        deviceid: Math.random(),
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      toast.success("Action submitted successfully!");
      console.log("Response:", response.data);
    } catch (error) {
      toast.error("Failed to submit the action!");
      console.error("Error:", error);
    }
  };

  const generateActivationCode = async (uuid) => {
    const data = JSON.stringify({
      metaData: {},
      data: {
        uuid: uuid,
      },
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: isLocal ? "http://localhost:8080/v1/auth/byPassKycOsvVerificationCode" : "https://prod-lb-n-1003.monyyapp.com/api/v1/auth/byPassKycOsvVerificationCode",
      headers: {
        source: "web",
        "Content-Type": "application/json",
        deviceid: Math.random(),
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log("Response:", JSON.stringify(response.data));

      // Update activation code for the specific row in filteredData
      const updatedData = filteredData.map((item) =>
        item.uuid === uuid
          ? { ...item, activationCode: response.data.data.code }
          : item
      );

      setFilteredData(updatedData); // Trigger re-render with updated data
      toast.success("Activation code generated successfully!");
      return response.data.data.code; // Return the response for further use if needed
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to generate activation code!");
      throw error;
    }
  };

  const handleWhatsAppClick = async (uuid) => {
    try {
      const data = JSON.stringify({
        metaData: {},
        data: {
          uuid: uuid,
        },
      });
  
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: isLocal ? "http://localhost:8080/v1/auth/getInteraktCustomerChatLink" : "https://prod-lb-n-1003.monyyapp.com/api/v1/auth/getInteraktCustomerChatLink",
        headers: {
          source: "web",
          "Content-Type": "application/json",
          deviceid: Math.random(),
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log("Response:", JSON.stringify(response.data));
      const link = response.data.data.link;
  
      if (link) {
        // Open the returned link in a new tab
        window.open(link, '_blank', 'noopener,noreferrer');
      } else {
        // alert('No link returned from the server.');
        toast.error("Failed to fetch WhatsApp link.");
      }
    } catch (error) {
      console.error('Error fetching the WhatsApp link:', error);
      // alert('Failed to fetch WhatsApp link.');
      toast.error("Failed to fetch WhatsApp link.");
    }
  };

  function convertToIST(timestamp) {
    timestamp = Number(timestamp);
    if (!timestamp) {
        return "NA";
    }
    const date = new Date(timestamp);
    const options = { 
        timeZone: 'Asia/Kolkata', 
        hour12: true, 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit' 
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    return formatter.format(date);
}

  const renderTableRows = () =>
    filteredData.map((row, index) => (
      <tr
        key={index}
        className="hover:bg-gray-100 text-xsmall border-b border-gray-300 text-gray-700"
      >
        <td className="px-2 py-1 border-r border-gray-300">{convertToIST(row.createdTimestamp)}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.fullName}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.countryCode}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.phoneNumber}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.dialCode}</td>
        <td className="px-2 py-1 border-r border-gray-300" style={{fontWeight:"bolder", fontSize:"16px"}}>{row.bookingId}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.geoCode}</td>
        <td className="px-2 py-1 border-r border-gray-300">{row.ukdLocation ? row.ukdLocation : row.location}</td>
        <td className="px-2 py-1 border-r border-gray-300">
          {row.activationCode ? (
            row.activationCode
          ) : (
            <Button
              variant="outline-secondary"
              onClick={() => generateActivationCode(row.uuid)}
              className="bg-purple-300 text-purple-800 px-2 py-1 rounded" style={{fontSize:"12px"}}
            >
              Generate
            </Button>
          )}
        </td>
        <td className="px-2 py-1 border-r border-gray-300">
          <select
            className="border rounded p-2"
            value={row.category || "Choose"}
            onChange={(e) => {
              row.category = e.target.value;
              setFilteredData([...filteredData]);
            }}
          >
            {["Choose", "Category 0", "Category 1", "Category 2", "Category 3"].map(
              (option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              )
            )}
          </select>
        </td>
        <td className="px-2 py-1 border-r border-gray-300">{row.nationalityType}</td>
        <td className={`px-2 py-1 border-r border-gray-300 
  ${row.preKycStatus === 'AGENT_ASSIGNED' ? 'bg-success text-white' : ''} 
  ${row.preKycStatus === 'DOCUMENTS_UPDATE_REQUIRED' ? 'bg-primary text-white' : ''} 
  ${row.preKycStatus === 'REJECT' ? 'bg-danger text-white' : ''} 
  ${row.preKycStatus === 'HOLD' ? 'bg-warning text-dark' : ''} 
  ${row.preKycStatus === 'PENDING' ? 'bg-secondary text-white' : ''}
`}>
<div style={{}} className={`px-2 py-1 border-r border-gray-300 
  ${row.preKycStatus === 'AGENT_ASSIGNED' ? 'bg-success text-white' : ''} 
  ${row.preKycStatus === 'DOCUMENTS_UPDATE_REQUIRED' ? 'bg-primary text-white' : ''} 
  ${row.preKycStatus === 'REJECT' ? 'bg-danger text-white' : ''} 
  ${row.preKycStatus === 'HOLD' ? 'bg-warning text-dark' : ''} 
  ${row.preKycStatus === 'PENDING' ? 'bg-secondary text-white' : ''}
`}>{row.preKycStatus ? row.preKycStatus : 'PENDING'}</div></td>
        <td className="px-2 py-1 border-r border-gray-300" style={{fontSize:"12px"}}>
          <select
            className={`border rounded p-2 ${
              row.action === "assign agent"
                ? "bg-success text-white"
                : row.action === "reject"
                ? "bg-danger text-white"
                : row.action === "update documents"
                ? "bg-primary text-dark"
                : row.action === "hold"
                ? "bg-warning text-dark"
                : ""
            }`}
            value={row.action || ""}
            onChange={(e) => {
              const updatedData = filteredData.map((item, i) =>
                i === index ? { ...item, action: e.target.value } : item
              );
              setFilteredData(updatedData);
            }}
          >
            <option value="">Select Action</option>
            <option value="assign agent">Assign Agent</option>
            <option value="reject">Reject</option>
            <option value="update documents">Update Documents</option>
            <option value="hold">On Hold</option>
          </select>
        </td>
        <td className="px-2 py-1 border-r border-gray-300">
  {row.isEditing ? (
    <textarea
      className="rounded text-xs p-2 w-full"
      placeholder="Add a comment"
      value={row.comments || ""}
      onChange={(e) => {
        const updatedData = filteredData.map((item, i) =>
          i === index ? { ...item, comments: e.target.value } : item
        );
        setFilteredData(updatedData);
      }}
      onBlur={() => {
        const updatedData = filteredData.map((item, i) =>
          i === index ? { ...item, isEditing: false } : item
        );
        setFilteredData(updatedData);
      }}
    />
  ) : row.comments ? (
    <div
  className="d-flex align-items-center justify-content-between"
  style={{ maxWidth: "200px"}}
>
  <span
    className="comments-text"
    style={{
      // display: "-webkit-box",
      // WebkitLineClamp: 2,
      // WebkitBoxOrient: "vertical",
      overflow: "hidden",
      // textOverflow: "ellipsis",
      lineHeight: "1.5em", // For consistent spacing
      maxHeight: "3em", // 2 lines of text
      cursor: "pointer",
      transition: "all 0.3s ease",
    }}
  >
    {row.comments}
  </span>
  <button
    onClick={() => {
      const updatedData = filteredData.map((item, i) =>
        i === index ? { ...item, isEditing: true } : item
      );
      setFilteredData(updatedData);
    }}
    className="btn btn-link text-decoration-none text-primary p-0 ms-2"
  >
    ✏️
  </button>
</div>

  ) : (
    <button
      onClick={() => {
        const updatedData = filteredData.map((item, i) =>
          i === index ? { ...item, isEditing: true } : item
        );
        setFilteredData(updatedData);
      }}
      className="text-gray-500" style={{border:"none", fontSize:"12px"}}
    >
      Remarks
    </button>
  )}
</td>
        <td className="px-2 py-1">
          <Button
            onClick={() => handleSubmit(row)}
            disabled={!row.category || !row.action || !row.comments} // Disable if category, action, or comment is empty
            style={{ backgroundColor: "#613AE7",fontSize:"12px" }}
            className="bg-purple-300 text-purple-800 px-2 py-1 rounded"
          >
            Submit
          </Button>
        </td>
        <button
        onClick={() => handleWhatsAppClick(row.uuid)}
        title="Contact on WhatsApp"
        style={{
          color: '#25d366',
          fontSize: '1.2em',
          textAlign: 'center',
          width: '100%',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon icon={faMessage} />
      </button>
      </tr>
    ));

  return (
    <div className="p-4">
      <div className="mb-4 flex items-center">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search Booking Id"
          className="border rounded px-4 py-2 w-full text-sm"
        />
        <button
          onClick={handleSearch}
          className="bg-purple-300 text-purple-800 px-4 py-2 ml-2 rounded text-sm border-0"
          style={{ marginLeft: "10px" }}
        >
          Search
        </button>
        <button
          onClick={fetchPaginatedData}
          className="px-4 py-2 ml-2 rounded text-sm border-0 bg-transparent"
        >
          Clear
        </button>
      </div>

      <div className="overflow-y-auto h-96 border border-gray-300 rounded">
        <table className="table-fixed w-full border-collapse" style={{fontSize:"12px"}}>
          <thead>
            <tr style={{ backgroundColor: "grey", color: "white", fontSize: "12px", textAlign: "center", width:"100%" }}>
              <th className="px-2 py-1 border-r border-gray-300">Submission Time</th>
              <th className="px-2 py-1 border-r border-gray-300">Name</th>
              <th className="px-2 py-1 border-r border-gray-300">Country Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Phone Number</th>
              <th className="px-2 py-1 border-r border-gray-300">Dial Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Booking Id</th>
              <th className="px-2 py-1 border-r border-gray-300">Geo Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Submission Location</th>
              <th className="px-2 py-1 border-r border-gray-300">Activation Code</th>
              <th className="px-2 py-1 border-r border-gray-300">Category</th>
              <th className="px-2 py-1 border-r border-gray-300">Nationality</th>
              <th className="px-2 py-1 border-r border-gray-300">Status</th>
              <th className="px-2 py-1 border-r border-gray-300">Action</th>
              <th className="px-2 py-1 border-r border-gray-300">Comments</th>
              <th className="px-2 py-1">Submit</th>
              <th className="px-2 py-1">Whatsapp</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>

      {!isSearching && (
        <div className="mt-4 flex justify-center">
          <Button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            className="bg-purple-300 text-purple-800 px-4 py-2 mx-1 rounded text-sm"
          >
            Previous
          </Button>
          <span className="px-4 py-2 text-sm">{currentPage}</span>
          <Button
            onClick={() => setCurrentPage((prev) => prev + 1)}
            className="bg-purple-300 text-purple-800 px-4 py-2 mx-1 rounded text-sm"
          >
            Next
          </Button>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};


export default PreKycDashboard;